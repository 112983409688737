import { app } from '@/main'

const logEventLog = function (url, data, resolve) {
  app.axios.post('/api/EventLog/' + url, data).then(() => {
    if (resolve === undefined) return

    resolve()
  })
}

const logAnalytics = function (eventName, type = '') {
  var dataLayer = window.dataLayer || []
  if (type === 'WidgetShown') {
    window.addEventListener('load', function () {
      dataLayer.push({
        event: eventName,
      })
    })
  } else {
    dataLayer.push({
      event: eventName,
    })
  }
}

const getEmail = function () {
  const body = document.querySelector('body')

  let email = null

  if (body !== null) {
    email = body.getAttribute('data-user-email')
  }

  if (email === '') {
    email = null
  }

  return email
}

const getAdIds = function (ads) {
  // gather ids
  const adIds = []

  for (let index = 0; index < ads.length; index++) {
    const ad = ads[index]
    adIds.push(ad.id)
  }

  return adIds
}

const getProductList = function (id) {
  const productList = []

  productList.push({
    id: id,
    price: 1,
    quantity: 1,
  })

  return productList
}

import { v4 as uuidv4 } from 'uuid'
import md5 from 'md5'

/*************************/
/* LogCriteoConfirmation */
/*************************/
export const LogCriteoConfirmation = function (id, email = '') {
  var dataLayer = window.dataLayer || []
  dataLayer.push({
    transactionID: uuidv4(),
    transactionItems: getProductList(id),
    email: getEmail() ?? md5(email),
    event: 'Criteo - Confirmation Event',
  })
}

/********************/
/* LogCriteoProduct */
/********************/
export const LogCriteoProduct = function (id) {
  var dataLayer = window.dataLayer || []
  dataLayer.push({
    productID: id,
    email: getEmail(),
    event: 'Criteo - Product Event',
  })
}

/**********************/
/* LogCriteoProSearch */
/**********************/
export const LogCriteoProSearch = function () {
  var dataLayer = window.dataLayer || []
  dataLayer.push({
    email: getEmail(),
    event: 'Criteo - ProSøg Event',
  })
}

/**********************/
/* LogCriteoAddToCart */
/**********************/
export const LogCriteoAddToCart = function (id) {
  var dataLayer = window.dataLayer || []
  dataLayer.push({
    addToCartItems: getProductList(id),
    email: getEmail(),
    event: 'Criteo - Add To Cart Event',
  })
}

/********************/
/* LogCriteoListing */
/********************/
export const LogCriteoListing = function (ads) {
  var dataLayer = window.dataLayer || []
  dataLayer.push({
    listingIDs: getAdIds(ads).slice(0, 3), // get first 3 as of the documentation
    email: getEmail(),
    event: 'Criteo - Listing Event',
  })
}

/***************************/
/* LogWidgetShownFinancing */
/***************************/
export const LogWidgetShownFinancing = function (adId, partnerId) {
  logAnalytics('Widget Financing [Santander] / Shown', 'WidgetShown')
  logEventLog('WidgetShownFinancing', {
    adId,
    partnerId,
  })
}

/*********************************/
/* LogWidgetCalculationFinancing */
/*********************************/
export const LogWidgetCalculationFinancing = function () {
  logAnalytics('Widget Financing [Santander] / Calculation')
}

/***************************************/
/* LogWidgetCreateApplicationFinancing */
/***************************************/
export const LogWidgetCreateApplicationFinancing = function (adId, partnerId) {
  logAnalytics('Widget Financing [Santander] / CreateApplication')
  logEventLog('WidgetCreateApplicationFinancing', {
    adId,
    partnerId,
  })
}

/***********************************/
/* ExchangePriceWidgetFloatClicked */
/***********************************/
export const ExchangePriceWidgetFloatClicked = function () {
  logAnalytics('Ad / Exchange Price float clicked')
}

/************************************/
/* ExchangePriceWidgetInlineClicked */
/************************************/
export const ExchangePriceWidgetInlineClicked = function () {
  logAnalytics('Ad / Exchange Price inline clicked')
}

/********************************/
/* LogWidgetShownInsuranceTechno */
/********************************/
export const LogWidgetShownInsuranceTechno = function () {
  logAnalytics('Widget Insurance [Techno] / Shown', 'WidgetShown')
}

/********************************/
/* LogWidgetShownInsuranceCodan */
/********************************/
export const LogWidgetShownInsuranceCodan = function () {
  logAnalytics('Widget Insurance [Codan] / Shown', 'WidgetShown')
}

/***********************************/
/* LogWidgetRedirectInsuranceCodan */
/***********************************/
export const LogWidgetRedirectInsuranceCodan = function () {
  logAnalytics('Widget Insurance [Codan] / Redirect')
}

/********************************/
/* LogWidgetShownInsuranceSamlino */
/********************************/
export const LogWidgetShownInsuranceSamlino = function () {
  logAnalytics('Widget Insurance [Samlino] / Shown', 'WidgetShown')
}

/***********************************/
/* LogWidgetInitiateApplicationInsuranceSamlino */
/***********************************/
export const LogWidgetInitiateApplicationInsuranceSamlino = function () {
  logAnalytics('Widget Insurance [Samlino] / Application initiated')
}

/********************************/
/* LogWidgetShownInsuranceSemler */
/********************************/
export const LogWidgetShownInsuranceSemler = function () {
  logAnalytics('Widget Insurance [Semler] / Shown', 'WidgetShown')
}

/********************************/
/* LogWidgetShownInsuranceSemler */
/********************************/
export const LogWidgetShownFinanceToyota = function () {
  logAnalytics('Widget Financing [Toyota] / Shown', 'WidgetShown')
}

/***********************************/
/* LogWidgetInitiateApplicationInsuranceSemler */
/***********************************/
export const LogWidgetInitiateApplicationInsuranceSemler = function () {
  logAnalytics('Widget Insurance [Semler] / Application initiated')
}

/***********************************/
/* LogWidgetSentApplicationInsuranceSemler */
/***********************************/
export const LogWidgetSentApplicationInsuranceSemler = function () {
  logAnalytics('Widget Insurance [Semler] / Application sent')
}

/************/
/* LogPrint */
/************/
export const LogPrint = function () {
  logAnalytics('Print')
}

/**************/
/* LogPrintAd */
/**************/
export const LogPrintAd = function (adId) {
  logEventLog('PrintAd', {
    adId,
  })
}

/***************************/
/* LogUserCreatedByWebsite */
/***************************/
export const LogUserCreatedByWebsite = function () {
  logAnalytics('User / Created by website')
}

/****************************/
/* LogUserCreatedByFacebook */
/****************************/
export const LogUserCreatedByFacebook = function () {
  logAnalytics('User / Created by Facebook')
}

/******************/
/* LogUserDeleted */
/******************/
export const LogUserDeleted = function () {
  logAnalytics('User / Delete')
}

/************************/
/* LogAdDeepLinkClicked */
/************************/
export const LogAdDeepLinkClicked = function (adId) {
  logEventLog('AdDeepLinkClicked', {
    adId,
  })
}

/*********************************/
/* LogCompanyHomepageLinkClicked */
/*********************************/
export const LogCompanyHomepageLinkClicked = function (companyId) {
  logAnalytics(`Company / Homepage link clicked`)

  logEventLog(`CompanyHomepageLinkClicked`, { companyId })
}

/****************************/
/* LogCompanyAddressClicked */
/****************************/
export const LogCompanyAddressClicked = function (companyId) {
  logAnalytics(`Company / Address clicked`)

  logEventLog(`CompanyAddressClicked`, { companyId })
}

/***********************/
/* LogPhoneNumberShown */
/***********************/
export const LogPhoneNumberShown = function (type, id) {
  logAnalytics(`${type} / Phone number shown`)

  const data = {}
  data[`${type.toLowerCase()}Id`] = id

  logEventLog(`${type}PhoneNumberShowed`, data)
}

/***********************/
/* LogPhoneNumberClick */
/***********************/
export const LogPhoneNumberClick = function (type, id) {
  return new Promise(resolve => {
    logAnalytics(`${type} / Phone number clicked`)

    const data = {}
    data[`${type.toLowerCase()}Id`] = id

    logEventLog(`${type}PhoneNumberClicked`, data, resolve)
  })
}

/***********************/
/* LogAccordionContactClicked */
/***********************/
export const LogAccordionContactClicked = function (type) {
  logAnalytics(`${type} / Accordion contact clicked`)
}

/********************/
/* LogAddToFavorite */
/********************/
export const LogAddToFavorite = function () {
  logAnalytics('Ad / Add to favorites')
}

/*************************/
/* LogAdShownDetailPixel */
/*************************/
export const LogAdShownDetailPixel = function (adId, type) {
  logEventLog('AdShownDetailPixel', {
    adId,
  })
  var dataLayer = window.dataLayer || []
  dataLayer.push({
    type: type,
    event: 'Ad / Detail All',
  })
}

/*************************/
/* LogAdShownCarDetail */
/*************************/
export const LogAdShownCarDetail = function () {
  logAnalytics('Ad / Detail Car')
}

/***************************/
/* LogAdShownInSearchPixel */
/***************************/
export const LogAdShownInSearchPixel = function (ads) {
  if (ads.length === 0) return

  const adIds = getAdIds(ads)

  logEventLog('AdShownInSearchPixel', {
    adIds,
  })
}

/******************/
/* LogContactSent */
/******************/
export const LogContactSent = function () {
  logAnalytics('Ad / Contact sent')
}

/**************/
/* LogBidSent */
/**************/
export const LogBidSent = function () {
  logAnalytics('Ad / Bid sent')
}

/***************************/
/* LogTestDriveRequestSent */
/***************************/
export const LogTestDriveRequestSent = function () {
  logAnalytics('Ad / Test drive request sent')
}

/******************************/
/* LogPrivateAdFrontpageClick */
/******************************/
export const LogPrivateAdFrontpageClick = function () {
  // hardcoded test of where the event is triggered
  if (location.pathname === '/') {
    logAnalytics('PrivateAd / Frontpage clicked')
  }
}

/************************/
/* LogPrivateAdCarClick */
/************************/
export const LogPrivateAdCarClick = function () {
  logAnalytics('PrivateAd / Car clicked')
}

/************************/
/* LogPrivateAdVanClick */
/************************/
export const LogPrivateAdVanClick = function () {
  logAnalytics('PrivateAd / Van clicked')
}

/*******************************/
/* LogPrivateAdMotorcycleClick */
/*******************************/
export const LogPrivateAdMotorcycleClick = function () {
  logAnalytics('PrivateAd / Motorcycle clicked')
}

/****************************/
/* LogPrivateAdCaravanClick */
/****************************/
export const LogPrivateAdCaravanClick = function () {
  logAnalytics('PrivateAd / Caravan clicked')
}

/************************/
/* LogPrivateAdActivate */
/************************/
export const LogPrivateAdActivate = function () {
  logAnalytics('PrivateAd / Activate')
}

/********************/
/* LogPrivateAdEdit */
/********************/
export const LogPrivateAdEdit = function () {
  logAnalytics('PrivateAd / Edit')
}

/**************************/
/* LogPrivateAdReactivate */
/**************************/
export const LogPrivateAdReactivate = function () {
  logAnalytics('PrivateAd / Reactivate')
}

/**********************/
/* LogPrivateAdDelete */
/**********************/
export const LogPrivateAdDelete = function () {
  logAnalytics('PrivateAd / Delete')
}

/**************************/
/* LogPrivateAdDeactivate */
/**************************/
export const LogPrivateAdDeactivate = function () {
  logAnalytics('PrivateAd / Deactivate')
}

/*********************/
/* LogCompanyContact */
/*********************/
export const LogCompanyContact = function () {
  logAnalytics('Company / Contact sent')
}

/****************/
/* LogSearchAds */
/****************/
export const LogSearchAds = function () {
  logAnalytics('Search / Ads')
}

/*********************/
/* LogSearchArticles */
/*********************/
export const LogSearchArticles = function () {
  logAnalytics('Search / Articles')
}

/**********************/
/* LogSearchCompanies */
/**********************/
export const LogSearchCompanies = function () {
  logAnalytics('Search / Companies')
}

/***********************/
/* AdTopFinanceClicked */
/***********************/
export const AdTopFinanceClicked = function () {
  logAnalytics('Ad / Top finance clicked')
}

/*************************/
/* AdTopInsuranceClicked */
/*************************/
export const AdTopInsuranceClicked = function () {
  logAnalytics('Ad / Top insurance clicked')
}

/***********************/
/* LogTopContactClicked */
/***********************/
export const LogTopContactClicked = function (type) {
  logAnalytics(`${type} / Top contact clicked`)
}

/***********************/
/* LogTopMobileContactClicked */
/***********************/
export const LogTopMobileContactClicked = function (type) {
  logAnalytics(`${type} / Top contact mobile clicked`)
}

/***********************/
/* LogTopMobilePhoneCallClicked */
/***********************/
export const LogTopMobilePhoneCallClicked = function (type, id) {
  //logAnalytics(`${type} / Top contact mobile phone call clicked`)

  return new Promise(resolve => {
    logAnalytics(`${type} / Top contact mobile phone call clicked`)

    const data = {}
    data[`${type}Id`] = id

    logEventLog(`${type}PhoneNumberClicked`, data, resolve)
  })
}

/***********************/
/* LogMiddlePageContactClicked */
/***********************/
export const LogMiddlePageContactClicked = function () {
  logAnalytics('Ad / Middle page contact clicked')
}

/*************************/
/* AdPriceFinanceClicked */
/*************************/
export const AdPriceFinanceClicked = function () {
  logAnalytics('Ad / Price finance clicked')
}

/***************************/
/* AdPriceInsuranceClicked */
/***************************/
export const AdPriceInsuranceClicked = function () {
  logAnalytics('Ad / Price insurance clicked')
}

/*************************/
/* AdPriceContactClicked */
/*************************/
export const AdPriceContactClicked = function () {
  logAnalytics('Ad / Price contact clicked')
}

/*************************/
/* OnlineSalesClicked */
/*************************/
export const OnlineSalesClicked = function (origin) {
  logAnalytics(`Ad / Online sales clicked - ${origin}`)
}

/*****************************/
/* RequirementSearchFinished */
/*****************************/
export const RequirementSearchFinished = function () {
  logAnalytics('Requirement Search / Finished')
}

/***************************/
/* RequirementSearchOpened */
/***************************/
export const RequirementSearchOpened = function () {
  logAnalytics('Requirement Search / Opened')
}

/******************************/
/* LogSolgtComMyAdsShown */
/******************************/
export const LogSolgtComMyAdsShown = function () {
  logAnalytics('PrivateAd / Solgt.com My Ads shown', 'WidgetShown')
}

/******************************/
/* LogSolgtComCreateShown */
/******************************/
export const LogSolgtComCreateShown = function () {
  logAnalytics('PrivateAd / Solgt.com Create shown', 'WidgetShown')
}

/******************************/
/* LogSolgtComMyAdsClicked */
/******************************/
export const LogSolgtComMyAdsClicked = function () {
  logAnalytics('PrivateAd / Solgt.com My Ads clicked')
}

/******************************/
/* LogSolgtComCreateClicked */
/******************************/
export const LogSolgtComCreateClicked = function () {
  logAnalytics('PrivateAd / Solgt.com Create clicked')
}

/******************************/
/* LogSolgtComWidgetShown */
/******************************/
export const LogSolgtComWidgetShown = function () {
  logAnalytics('PrivateAd / Solgt.com Widget shown', 'WidgetShown')
}

/******************************/
/* LogExchangePriceStep1Shown */
/******************************/
export const LogExchangePriceStep1Shown = function () {
  logAnalytics('Widget Exchangeprice / Step1 Shown', 'WidgetShown')
}

/***************************************************/
/* LogExchangePriceStep1RegistrationNumberIncluded */
/***************************************************/
export const LogExchangePriceStep1RegistrationNumberIncluded = function () {
  logAnalytics('Widget Exchangeprice / Step1 RegistrationNumberIncluded')
}

/******************************/
/* LogExchangePriceStep2Shown */
/******************************/
export const LogExchangePriceStep2Shown = function () {
  logAnalytics('Widget Exchangeprice / Step2 Shown')
}

/******************************/
/* LogExchangePriceStep3Shown */
/******************************/
export const LogExchangePriceStep3Shown = function () {
  logAnalytics('Widget Exchangeprice / Step3 Shown')
}

/******************************/
/* LogExchangePriceStep4Shown */
/******************************/
export const LogExchangePriceStep4Shown = function () {
  logAnalytics('Widget Exchangeprice / Step4 Shown')
}

/******************************/
/* LogExchangePriceStep5Shown */
/******************************/
export const LogExchangePriceStep5Shown = function () {
  logAnalytics('Widget Exchangeprice / Step5 Shown')
}

/***********************************/
/* LogExchangePriceStep5PriceFound */
/***********************************/
export const LogExchangePriceStep5PriceFound = function () {
  logAnalytics('Widget Exchangeprice / Step5 PriceFound')
}

/***************************/
/* PrivateAdFlowStep1Shown */
/***************************/
export const PrivateAdFlowStep1Shown = function () {
  logAnalytics('PrivateAdFlow / Step1 shown')
}

/**********************************/
/* PrivateAdFlowStep1LicensePlate */
/**********************************/
export const PrivateAdFlowStep1LicensePlate = function () {
  logAnalytics('PrivateAdFlow / Step1 - Selected by licenseplate')
}

/*************************************/
/* PrivateAdFlowStep1ManualSelection */
/*************************************/
export const PrivateAdFlowStep1ManualSelection = function () {
  logAnalytics('PrivateAdFlow / Step1 - Manual selection')
}

/***************************/
/* PrivateAdFlowStep2Shown */
/***************************/
export const PrivateAdFlowStep2Shown = function () {
  logAnalytics('PrivateAdFlow / Step2 shown')
}

/***************************/
/* PrivateAdFlowStep3Shown */
/***************************/
export const PrivateAdFlowStep3Shown = function () {
  logAnalytics('PrivateAdFlow / Step3 shown')
}

/***************************/
/* PrivateAdFlowStep4Shown */
/***************************/
export const PrivateAdFlowStep4Shown = function () {
  logAnalytics('PrivateAdFlow / Step4 shown')
}

/***************************/
/* PrivateAdFlowStep5Shown */
/***************************/
export const PrivateAdFlowStep5Shown = function () {
  logAnalytics('PrivateAdFlow / Step5 shown')
}

/************************************/
/* PrivateAdFlowStep5PublishClicked */
/************************************/
export const PrivateAdFlowStep5PublishClicked = function () {
  logAnalytics('PrivateAdFlow / Step5 publish clicked')
}

/**************************/
/* LogSliderCardAdClicked */
/**************************/
export const LogSliderCardAdClicked = function () {
  logAnalytics('Cardslider / Ad clicked')
}

/*************************/
/* LogArticleItemClicked */
/*************************/
export const LogArticleItemClicked = function () {
  logAnalytics('Article / clicked')
}

/*******************/
/* LogFacebookView */
/*******************/
export const LogFacebookView = function (
  id,
  title,
  price,
  adFacebookRetargetingValues
) {
  var fbq = window.fbq || undefined
  if (fbq !== undefined) {
    fbq('track', 'ViewContent', {
      content_ids: [id],
      content_name: title,
      value: price,
      currency: 'DKK',
      retargetingValues: adFacebookRetargetingValues,
    })
  }
}

/*********************/
/* LogFacebookSearch */
/*********************/
export const LogFacebookSearch = function () {
  var fbq = window.fbq || undefined
  if (fbq !== undefined) {
    fbq('track', 'Search')
  }
}

/****************************/
/* LogFacebookAddToWishlist */
/****************************/
export const LogFacebookAddToWishlist = function () {
  var fbq = window.fbq || undefined
  if (fbq !== undefined) {
    fbq('track', 'AddToWishlist')
  }
}

/*******************/
/* LogFacebookLead */
/*******************/
export const LogFacebookLead = function () {
  var fbq = window.fbq || undefined
  if (fbq !== undefined) {
    fbq('track', 'Lead')
  }
}
/*************************/
/* LogShortcutBannerSolgtComItemClicked */
/*************************/
export const LogShortcutBannerSolgtComItemClicked = function () {
  logAnalytics('ShortcutBanner / Solgt.com link clicked')
}

/*************************/
/* LogShortcutBannerCreateAdItemClicked */
/*************************/
export const LogShortcutBannerCreateAdItemClicked = function () {
  logAnalytics('ShortcutBanner / Create ad link clicked')
}

/*************************/
/* LogShortcutBannerRequirementSearchItemClicked */
/*************************/
export const LogShortcutBannerRequirementSearchItemClicked = function () {
  logAnalytics('ShortcutBanner / Requirement search link clicked')
}

/*************************/
/* LogShortcutBannerStartSearchItemClicked */
/*************************/
export const LogShortcutBannerStartSearchItemClicked = function () {
  logAnalytics('ShortcutBanner / Start search link clicked')
}

/*************************/
/* LogViewAllRelatesAdsClicked */
/*************************/
export const LogViewAllRelatesAdsClicked = function () {
  logAnalytics('Licenseplate page / CardAdSlider view all clicked')
}
/*************************/
/* LogFactBoxLinkClicked */
/*************************/
export const LogFactBoxLinkClicked = function () {
  logAnalytics('Licenseplate page / Factbox link clicked')
}
