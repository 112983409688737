const historyObj = {
  stack: [],
}

const push = (stack, callback, historyRef) => {
  history.pushState(null, '', null)
  stack.push({ callback: callback, historyRef: historyRef })
}

const pop = () => {
  if (historyObj.stack.length === 0) return
  history.back()
}

const handlePopstate = () => {
  if (historyObj.stack.length == 0) return
  const item = historyObj.stack.pop().callback
  item()
}

const isNullOrUndefined = ref => {
  return ref === null || typeof ref === 'undefined'
}

window.addEventListener('popstate', handlePopstate)

// START instance
// Denne instance er det tilgængelige i vue komponenter gennem this.history.FUNCTION
const historyInstance = {
  pop: () => {
    pop()
  },
  push: (callback, historyRef) => {
    push(historyObj.stack, callback, historyRef)
  },
  getStackSize: () => {
    return historyObj.stack.length
  },
  popAll: popRef => {
    for (let i = historyObj.stack.length - 1; i >= 0; i--) {
      const refObj = historyObj.stack[i]

      if (refObj.historyRef === 'pop-all-immune') return
      if (!isNullOrUndefined(popRef) && !isNullOrUndefined(popRef.$parent)) {
        if (popRef.$parent === refObj.historyRef) {
          return
        }
      }

      window.setTimeout(() => {
        pop()
      }, 25)
    }
  },
}
// END instance

export default historyInstance
