export default {}

/************************/
/* DECODE HTML ENTITIES */
/************************/
export function decodeHTMLEntities(text) {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = text
  return textArea.value
}

/**********************/
/* THOUSAND SEPARATOR */
/**********************/
export function thousandSeparator(value) {
  return value !== null && value !== undefined && (value + '').length > 0
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : ''
}

/**************/
/* GET PARENT */
/**************/
export function getParent(element, className) {
  const parentElement = element.parentNode

  if (parentElement === null) {
    return null
  }

  if (
    className === undefined ||
    (parentElement.classList !== undefined &&
      parentElement.classList.contains(className))
  ) {
    return parentElement
  }

  return getParent(parentElement, className)
}

/************/
/* DEBOUNCE */
/************/
export function debounce(func, wait, immediate = false) {
  // Debounce function from https://davidwalsh.name/javascript-debounce-function
  let timeout
  // eslint-disable-next-line
  return (...args) => {
    const context = this
    const later = () => {
      timeout = undefined
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

/****************************/
/* TOGGLE DOCUMENT OVERFLOW */
/****************************/
export function toggleDocumentOverflow(
  hideOverflow,
  overflowHidden = 'overflow-hidden-sm'
) {
  const html = document.getElementsByTagName('html')[0],
    classList = html.classList

  if (hideOverflow) {
    classList.add(overflowHidden)
  } else {
    classList.remove(overflowHidden)
  }
}

/**************************/
/* ACTIVATE SINGLE SELECT */
/**************************/
export function activateSingleSelect(element) {
  function UnCheckGroup(group, currentTarget) {
    group.forEach(val => {
      if (val != currentTarget) val.checked = false
    })
  }

  if (element) {
    const elementList = [...element.getElementsByClassName('js-check-group')]

    elementList.forEach(element => {
      // eslint-disable-next-line
      element.addEventListener('change', evt => {
        const element = evt.currentTarget
        if (element.checked) {
          const dataGroup = element.getAttribute('data-group')

          const dataGroupList = []
          for (let j = 0; j < elementList.length; j++) {
            if (elementList[j].getAttribute('data-group') === dataGroup)
              dataGroupList.push(elementList[j])
          }
          UnCheckGroup(dataGroupList, element)
        }
      })
    })
  }
}

/*************/
/* ADD CLASS */
/*************/
export function addClass(element, className) {
  element.classList.add(className)
}

/****************/
/* REMOVE CLASS */
/****************/
export function removeClass(element, className) {
  element.classList.remove(className)
}

/******************/
/* SCROLL ANIMATE */
/******************/
export function scrollAnimate(
  element,
  to,
  direction,
  duration,
  // eslint-disable-next-line
  callback = () => {}
) {
  if (direction === undefined) {
    direction = 'scrollTop'
  }

  if (duration === undefined) {
    duration = 500
  }

  if (duration <= 0) {
    callback()
    return
  }

  const difference = to - element[direction],
    perTick = (difference / duration) * 10

  // eslint-disable-next-line
  setTimeout(() => {
    element[direction] = element[direction] + perTick

    if (element[direction] === to) {
      callback()

      return
    }

    scrollAnimate(element, to, direction, duration - 10, callback)
  }, 10)
}

/**************/
/* SET COOKIE */
/**************/
export function setCookie(name, value, lifetimeInNoOfDays) {
  let expires = ''

  if (lifetimeInNoOfDays === undefined) {
    lifetimeInNoOfDays = 356 // 1 year
  }

  const date = new Date()
  expires = new Date(
    date.setTime(
      // lifetimeInNoOfDays *
      // 24 (hours) *
      // 60 (minutes) *
      // 60 (seconds) *
      // 1000 (as milliseconds)
      date.getTime() + lifetimeInNoOfDays * 24 * 60 * 60 * 1000
    )
  ).toUTCString()

  document.cookie = name + '=' + value + '; expires=' + expires
}

/**************/
/* GET COOKIE */
/**************/
export function getCookie(name) {
  const re = new RegExp(name + '=([^;]+)')
  const value = re.exec(document.cookie)
  return value != null ? unescape(value[1]) : null
}

/*****************/
/* REMOVE COOKIE */
/*****************/
export function removeCookie(name) {
  setCookie(name, '', -1)
}

/*********************/
/* SHARE ON FACEBOOK */
/*********************/
export function shareOnFacebook(url) {
  const hostName =
    window.location.protocol +
    '//' +
    (window.location.hostname != 'localhost'
      ? window.location.hostname
      : 'dev4.biltorvet.dk')

  window.open(
    'http://www.facebook.com/sharer.php?u=' +
      encodeURIComponent(hostName + url) +
      '&t=' +
      encodeURIComponent(document.title),
    'sharer',
    'toolbar=0,status=0,width=626,height=436'
  )
}

/************************************************************/
/* ESCAPING SPECIAL CHARACTERS HAVING A REGEX FUNCTIONALITY */
/************************************************************/
export function sanitizeRegexString(searchString) {
  return searchString
    .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    .replace(/-/g, '\\x2d')
}

/****************************/
/* HIGHLIGHT TEXT IN STRING */
/****************************/
export function highlightTextInString(text, searchString) {
  return text.replace(
    new RegExp(sanitizeRegexString(searchString.trim()), 'ig'),
    match => {
      return '<strong>' + match + '</strong>'
    }
  )
}
